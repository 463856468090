<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">Consider the following second order reaction:</p>
      <p />
      <p class="pl-10 mb-4">
        <chemical-latex content="2A->B," />
      </p>

      <p class="mb-2">
        In a reaction where
        <stemble-latex content="$\text{[A]}_0\text{ =}$" />
        <number-value :value="concA0" unit="\text{M,}" />
        the concentration of reactant
        <stemble-latex content="$\text{A}$" />
        was found to drop to
        <number-value :value="concAt" unit="\text{M,}" />
        after
        <number-value :value="rxnTime1" unit="\text{s.}" />
        Determine the second order rate constant
        <stemble-latex content="$\text{k}$" />
        for this reaction.
      </p>

      <p>
        <v-row no-gutters>
          <v-col class="d-flex mt-0 mb-0 pb-0 mr-0 pr-0" cols="8" sm="4">
            <calculation-input
              v-model="inputs.rateConstant"
              class="mb-5"
              prepend-text="$\text{k}:$"
              :disabled="!allowEditing"
            />
          </v-col>
          <v-col class="d-flex mt-0 mb-0 pb-0 pl-0 ml-0" cols="2" sm="4">
            <v-select
              v-model="inputs.units"
              :items="items"
              item-text="text"
              item-value="value"
              label="Select Units"
              outlined
            >
              <template #item="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
              <template #selection="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
            </v-select>
          </v-col>
        </v-row>
      </p>

      <p class="mb-2">
        b) What percentage of
        <stemble-latex content="$\text{A}$" />
        would remain after
        <number-value :value="rxnTime2" unit="\text{min?}" />
      </p>

      <calculation-input
        v-model="inputs.pctRemaining"
        prepend-text="$\text{\% A}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question317',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        rateConstant: null,
        units: null,
        pctRemaining: null,
      },
      items: [
        {
          text: 'M/s',
          value: 'M/s',
        },
        {
          text: 'M<sup>-1</sup> s<sup>-1</sup>',
          value: 'M-1s-1',
        },
        {
          text: 'M<sup>-2</sup> s<sup>-1</sup>',
          value: 'M-2s-1',
        },
        {
          text: 's<sup>-1</sup>',
          value: 's-1',
        },
        {
          text: 'M s<sup>-2</sup>',
          value: 'Ms-2',
        },
      ],
    };
  },
  computed: {
    concA0() {
      return this.taskState.getValueBySymbol('concA0').content;
    },
    concAt() {
      return this.taskState.getValueBySymbol('concAt').content;
    },
    rxnTime1() {
      return this.taskState.getValueBySymbol('rxnTime1').content;
    },
    rxnTime2() {
      return this.taskState.getValueBySymbol('rxnTime2').content;
    },
  },
};
</script>
